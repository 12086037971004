import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import React, {useState} from 'react'
import { Textarea } from "@nextui-org/input";
import { Button } from '@nextui-org/button';
import { MoveUp } from 'lucide-react';

const ChatPage = () => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");

    const handleSetMessage = (value: string) => {
        const filteredValue = value.replace(/\n/g, "");
        setMessage(filteredValue);
      };
  return (
    <div className="bg-black flex flex-col h-screen w-full justify-between scroll-smooth">
        <Navbar/>
        <div className='h-full w-full !max-w-3xl items-center px-4 py-8 pt-12 mx-auto sm:px-6 flex flex-col gap-8 text-md'>
            {show ? (
                <div className="flex flex-col relative gap-6 w-full flex-1 z-10 pt-28 pb-8 !max-w-4xl">
                    
                </div>
                
            ) : (
                <div className='flex flex-col gap-6 items-center flex-1 justify-center w-full z-10 !max-w-4xl'>
                    <img
                    src="/logo.png"
                    width={200}
                    height={200}
                    alt="about-picture"
                    />
                </div>
            )}
            

            <div className='flex flex-col w-full bg-black pb-0 sticky z-10 bottom-0'>
            <Textarea
              value={message}
              onValueChange={handleSetMessage}
              className="dark text-white"
              placeholder="Chat with rental home"
              size="lg"
              style={{ height: 25 }}
              minRows={1}
              rows={1}
              maxRows={3}
              radius="lg"
              classNames={{
                innerWrapper: "items-center text-white",
                inputWrapper: "p-2.5 pl-5 rounded-2xl",
              }}
              onKeyDown={async (e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  if (message.trim().length > 0) {
                    setShow(true);
                    setMessage("");
                  }
                }
              }}
              endContent={
                <Button
                  radius="lg"
                  color="secondary"
                  className="light text-lg"
                  isDisabled={message.trim().length === 0}
                  onClick={async () => {
                    setShow(true);
                    setMessage("");
                  }}
                  isIconOnly
                >
                  <MoveUp />
                </Button>
              }
              fullWidth
            />
            </div>

        </div>
        <Footer/>
    </div>
  )
}

export default ChatPage