import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./globals.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <main className="flex flex-col w-full h-screen">
      <App />
  </main>
);

