import React, {useState} from 'react';
import { Modal, ModalContent, ModalBody} from "@nextui-org/modal";
import { Button } from '@nextui-org/button';
import { Input } from '@nextui-org/input';
import { Eye, EyeOff } from 'lucide-react';


const Login = ({ isOpen, onClose }: any) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className='w-full text-black'>
      <Modal isOpen={isOpen} onOpenChange={onClose}>
        <ModalContent>
            <ModalBody className="flex flex-col gap-4 px-8 py-6 text-black">
              <h3 className="text-xl font-bold px-3">Login</h3>
              <div className="flex flex-col gap-3 w-full">
                <Input 
                  className='pb-0 ' 
                  label="Email Address"
                  name="email"
                  type="email"
                  variant="bordered"/>
                
                <Input
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="bordered"
                  endContent={
                    <Button
                    radius="full"
                    variant="light"
                    className="text-xl"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    isIconOnly
                    >
                      {showPassword ? <Eye /> : <EyeOff />}
                    </Button>
                  }
                />
              </div>
                <div className="flex items-center justify-end px-1 pb-2">
                  <span
                    className="cursor-pointer text-primary text-sm hover:underline">
                    Forgot password?
                  </span>
                </div>
                <Button
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Log In
                </Button>
              
              <p className="text-center text-small">
                {"Don't have an account?"}&nbsp;
                <span className="cursor-pointer text-primary hover:underline">
                  Register
                </span>
              </p>
            </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Login;
