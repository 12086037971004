import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, useMemo } from 'react';
import { ChevronDown, Plus, Minus, MapPinned, LoaderCircle } from 'lucide-react';
import { FaStar } from 'react-icons/fa';
import { Input } from "@nextui-org/input";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Switch,
} from "@nextui-org/react";
import { Select, SelectItem } from "@nextui-org/select";
import { Pagination } from "@nextui-org/pagination";
import GoogleMapReact from "google-map-react";
import axios from 'axios';
import BookingFilters from 'src/app/components/booking-filters/App';
import { Modal, ModalContent, ModalBody, useDisclosure } from "@nextui-org/react";
import useFilterStore from "src/app/components/booking-filters/useFilterStore";




type Items = {
  name: string,
  description: string,
  count: number
};

type Guest = {
  age: Number | null;
  type: 'adult' | 'child' | 'infant' | 'pet';
};

const rentBedRooms = [
  { text: "1+", value: 1 },
  { text: "2+", value: 2 },
  { text: "3+", value: 3 },
  { text: "4+", value: 4 },
  { text: "5+", value: 5 },
  { text: "6+", value: 6 },
  { text: "7+", value: 7 },
  { text: "8+", value: 8 },
  { text: "9+", value: 9 },
  { text: "10+", value: 10 },
];
const rentBathRooms = [
  { text: "1+", value: "1" },
  { text: "2+", value: "2" },
  { text: "3+", value: "3" },
  { text: "4+", value: "4" },
  { text: "5+", value: "5" },
  { text: "6+", value: "6" },
  { text: "7+", value: "7" },
  { text: "8+", value: "8" },
  { text: "9+", value: "9" },
  { text: "10+", value: "10" },
];

const PropertiesPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [locationSearch, setLocationSearch] = useState(searchParams.get('location') || "");
  const [checkIn, setCheckIn] = useState(searchParams.get('checkIn') || "")
  const [checkOut, setCheckOut] = useState(searchParams.get('checkOut') || "");
  const [guestsSearch, setGuestsSearch] = useState(searchParams.get('guests') || "");
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);
  const [isPriceOpen, setIsPriceOpen] = useState(false);
  const [mapOpen, setMapOpen] = useState(true);
  const [min, setmin] = useState("")
  const [max, setmax] = useState("")
  const [bedrooms, setBedrooms] = useState(1);
  const [loading, setLoading] = useState(false); 
  const [notFound, setNotFound] = useState(false);


  const guestDataString = guestsSearch || "[]"; 
  let guestData;

  try {
    guestData = JSON.parse(guestDataString);
  } catch (error) {
    console.error("Failed to parse guest data:", error);
    guestData = []; 
  }

  const initializeItems = (guests) => {
    let adultsCount = 0;
    let childrenCount = 0;
    let infantsCount = 0;
    let petsCount = 0; 

    guests.forEach(guest => {
      if (guest.type === 'adult') {
        adultsCount++;
      } else if (guest.age <= 2) {
        infantsCount++;
      } else {
        childrenCount++;
      }
    });

    return [
      { name: 'Adults', description: 'Ages 13 or above', count: adultsCount },
      { name: 'Children', description: 'Ages 2-12', count: childrenCount },
      { name: 'Infants', description: 'Under 2', count: infantsCount },
      { name: 'Pets', description: 'Service animal?', count: petsCount },
    ];
  };

  const [items, setItems] = useState<Items[]>(initializeItems(guestData));

  const getGuestSummary = (): Guest[] => {
    const adultsCount = items.find(item => item.name === 'Adults')?.count || 0;
    const childrenCount = items.find(item => item.name === 'Children')?.count || 0; 
    const infantsCount = items.find(item => item.name === 'Infants')?.count || 0;
    const petsCount = items.find(item => item.name === 'Pets')?.count || 0;
  
    const guestSummary: Guest[] = [];
  
    
    for (let i = 0; i < adultsCount; i++) {
      guestSummary.push({ type: 'adult', age: null}); 
    }
  
    
    for (let i = 0; i < childrenCount; i++) {
      guestSummary.push({ type: 'child', age: 12 }); 
    }
  
    
    for (let i = 0; i < infantsCount; i++) {
      guestSummary.push({ type: 'child', age: 1 }); 
    }
  
    return guestSummary;
  };
  

  const handleIncrement = (index: number) => {
    setItems(prevItems =>
      prevItems.map((item, i) =>
        i === index ? { ...item, count: item.count + 1 } : item
      )
    );
  };

  const handleDecrement = (index: number) => {
    setItems(prevItems =>
      prevItems.map((item, i) =>
        i === index ? { ...item, count: Math.max(0, item.count - 1) } : item
      )
    );
  };

  function convertToK(value: string = "", type: string = "") {
    try {
      let num = parseInt(value.replace('$', '').replace(',', ''));
      return `$${num / 1000}k`;
    } catch (error) {
      return type
    }
  }
  
  const summary = useMemo(() => getGuestSummary(), [items]);
  
  const [stays, setStays] = useState<any[]>([]);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  

const searchStays = async (locationSearch, checkIn, checkOut, bedrooms, guests) => {
  try {
    const response = await axios.post('http://89.116.50.93:5000/api/stays/search', {
      locationSearch,
      checkIn,
      checkOut,
      bedrooms,
      guests,
    });
    console.log('Response data:', response.data); // Log the response data
    return response.data; // Return the data for further use
  } catch (error:any) {
    console.error('Error fetching stays:', error.response?.data || error.message);
    throw error; // Rethrow the error for handling in the calling component
  }
};

useEffect(() => {
  let isMounted = true; // Track if component is mounted

  const fetchData = async () => {
    setLoading(true);
    
    await new Promise(resolve => setTimeout(resolve, 500)); // Introduce a delay

    try {
      const fetchedStays = await searchStays(locationSearch, checkIn, checkOut, bedrooms, summary);
      if (isMounted) {
        setStays(fetchedStays);

        if (fetchedStays.length > 0) {
          setCoordinates({
            lat: fetchedStays[0].accommodation.location.geographic_coordinates.latitude,
            lng: fetchedStays[0].accommodation.location.geographic_coordinates.longitude,
          });
        }
        
      }
    } catch (error) {
      console.error('Error during search:', error);
      if (isMounted) {
        setNotFound(true)
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }
  };

  if (locationSearch || checkIn || checkOut || bedrooms) { 
    fetchData();
  }

  return () => {
    isMounted = false;
  };
}, [locationSearch, checkIn, checkOut, bedrooms, summary]);

  const { priceRange, rating, selectedAmenities } = useFilterStore();

  const filteredStays = stays?.filter((property: any) => {
    const propertyRating = property.accommodation.rating;
    const propertyPrice = property.cheapest_rate_total_amount;
    const propertyAmenities = property.accommodation.amenities.map((amenity: any) => amenity.type);
    console.log(propertyAmenities)
    
    const hasSelectedAmenities = selectedAmenities.every((amenity: string) =>
      propertyAmenities.includes(amenity)
    );
  
    return (
      propertyRating >= rating &&
      propertyPrice >= priceRange[0] &&
      propertyPrice <= priceRange[1] &&
      hasSelectedAmenities
    );
  });

  return (
    <div className="bg-white flex flex-col min-h-screen w-full justify-between">
      <Navbar />
      <div className='h-full w-full px-4  mx-auto sm:px-6 flex flex-col gap-8 text-md flex-grow'>
        {/*Filter Options*/}
        <div className="sticky top-[64px] px-5 py-4 z-40 bg-[#ffffff] min-h-16 border-b-1 border-zinc-100">
          <div className='flex items-center'>
            {/*Location*/}
            <Input
              classNames={{
                input: ["text-left text-default-500"],
                innerWrapper: "pb-0",
                inputWrapper: "bg-white shadow-none"
              }}
              value={locationSearch}
              className='h-full'
              radius='full'
              size='sm'
              label="Location"
              fullWidth
              onChange={(e) => setLocationSearch(e.target.value)}
            />
            {/*Guests*/}
            <Popover
              placement="bottom-start"
              color="secondary"
              className='h-full'
              isOpen={isGuestsOpen}
              onOpenChange={(open) => setIsGuestsOpen(open)}
            >
              <PopoverTrigger>
                <Input
                  radius='full'
                  size='sm'
                  label='Guests'
                  value={items
                    .filter(item => item.count > 0)
                    .map(item => `${item.name}: ${item.count}`)
                    .join(', ') || ""}
                  fullWidth
                  classNames={{
                    input: ["text-left text-default-500"],
                    innerWrapper: "pb-0",
                    inputWrapper: "bg-white shadow-none"
                  }}
                  className='h-full'
                  endContent={
                    <Button
                      size="sm"
                      radius="full"
                      variant="light"
                      className="text-base h-full"
                      style={{ minWidth: "auto", transition: "background-color 0.3s ease" }}
                      onClick={() => setIsGuestsOpen(true)}
                    >
                      <ChevronDown size={16} />
                    </Button>
                  }
                />
              </PopoverTrigger>
              <PopoverContent className="bg-white">
                {items.map((item, index) => (
                  <div key={item.name} className="grid grid-cols-[150px_130px]  w-full justify-between items-center ml-5 mt-3 mb-3">
                    <div className="flex-1">
                      <div className="font-semibold">{item.name}</div>
                      <div style={{ color: "grey", fontSize: 14 }} className="text-sm text-muted-foreground">{item.description}</div>
                    </div>
                    <div className="grid grid-cols-[30px_50px_30px]  items-center">
                      <Button
                        variant="flat"
                        style={{ width: 30, minWidth: 30 }}
                        className="h-8  rounded-full flex items-center p-0 justify-center"
                        onClick={() => handleDecrement(index)}
                        disabled={item.count === 0}
                      >
                        <Minus className="h-4 w-4" />
                      </Button>
                      <span className="text-center">{item.count}</span>
                      <Button
                        variant="flat"
                        style={{ width: 30, minWidth: 30 }}
                        className="h-8 bg-black text-white rounded-full flex items-center  p-0  justify-center"
                        onClick={() => handleIncrement(index)}
                      >
                        <Plus className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                ))}
              </PopoverContent>
            </Popover>

            {/*Beds*/}
            <Select
              label="Bedroom"
              defaultSelectedKeys={"1"}
              radius="full"
              className="max-w-xs"
              classNames={{
                trigger: "bg-white shadow-none",
              }}
              onChange={(e) => setBedrooms(Number(e.target.value))}
            >
              {rentBedRooms.map((bed) => (
                <SelectItem key={bed.value} value={bed.value}>
                  {bed.text}
                </SelectItem>
              ))}
            </Select>

            {/*Bath*/}
            <Select
              label="Bath"
              radius="full"
              className="max-w-xs"
              classNames={{
                trigger: "bg-white shadow-none",
              }}
            >
              {rentBathRooms.map((bath) => (
                <SelectItem key={bath.value}>
                  {bath.text}
                </SelectItem>
              ))}
            </Select>

            {/*Price*/}
            <Popover
              placement="bottom-start"
              color="secondary"
              className='h-full'
              isOpen={isPriceOpen}
              onOpenChange={(open) => setIsPriceOpen(open)}
            >
              <PopoverTrigger>
                <Input
                  radius='full'
                  size='sm'
                  label='Price'
                  value={
                    (min && max) ? `${convertToK(min)} - ${convertToK(max)}` :
                      (min) ? `${convertToK(min)} minimum` :
                        (max) ? `${convertToK(max)} maximum` : ''
                  }
                  classNames={{
                    input: ["text-left text-default-500"],
                    innerWrapper: "pb-0",
                    inputWrapper: "bg-white shadow-none"
                  }}
                  className='h-full'
                  fullWidth
                  endContent={
                    <Button
                      size="sm"
                      radius="full"
                      variant="light"
                      className="text-base h-full"
                      style={{ minWidth: "auto", transition: "background-color 0.3s ease" }}
                      onClick={() => setIsPriceOpen(true)}
                    >
                      <ChevronDown size={16} />
                    </Button>
                  }
                />
              </PopoverTrigger>
              <PopoverContent className="bg-white">
                <div style={{ width: 350 }} className="grid grid-cols-2 gap-3">
                  <Select
                    label="Minimum"
                    value={min}
                    onChange={(e) => (setmin(e.target.value))}
                    className="max-w-xs" >
                    <SelectItem key={"$1,000"}>$1,000</SelectItem>
                    <SelectItem key={"$2,500"}>$2,500</SelectItem>
                    <SelectItem key={"$5,000"}>$5,000</SelectItem>
                    <SelectItem key={"$10,000"}>$10,000</SelectItem>
                    <SelectItem key={"$15,000"}>$15,000</SelectItem>
                    <SelectItem key={"$20,000"}>$20,000</SelectItem>
                    <SelectItem key={"$25,000"}>$25,000</SelectItem>
                    <SelectItem key={"$50,000"}>$50,000</SelectItem>
                    <SelectItem key={"$100,000"}>$100,000</SelectItem>
                    <SelectItem key={"$250,000"}>$250,000</SelectItem>
                  </Select>
                  <Select
                    label="Maximum"
                    value={max}
                    onChange={(e) => (setmax(e.target.value))}
                    className="max-w-xs" >
                    <SelectItem key={"$1,000"}>$1,000</SelectItem>
                    <SelectItem key={"$2,500"}>$2,500</SelectItem>
                    <SelectItem key={"$5,000"}>$5,000</SelectItem>
                    <SelectItem key={"$10,000"}>$10,000</SelectItem>
                    <SelectItem key={"$15,000"}>$15,000</SelectItem>
                    <SelectItem key={"$20,000"}>$20,000</SelectItem>
                    <SelectItem key={"$25,000"}>$25,000</SelectItem>
                    <SelectItem key={"$50,000"}>$50,000</SelectItem>
                    <SelectItem key={"$100,000"}>$100,000</SelectItem>
                    <SelectItem key={"$250,000"}>$250,000</SelectItem>
                  </Select>

                </div>
              </PopoverContent>
            </Popover>
            <Switch
              size="lg"
              thumbIcon={<MapPinned className="text-sm bg-black" />}
              defaultSelected
              onValueChange={(isSelected) => { setMapOpen(isSelected); }}
            />

            {/*Filter btn*/}

            <FiltersModel></FiltersModel>

          </div>
        </div>
        {mapOpen ? (
          <div className='h-full w-full  flex justify-between'>

            <div className='flex w-full'>
              <div className='flex-1'>

                {/* Default Design
                      <div className='grid grid-cols-2 pt-8 gap-6 max-w-screen-xl w-full mx-auto px-4 sm:px-6'>
                        {data.map((property: any, index: number) => (
                            <Link
                            to={`/rent/properties/${property._id}`}
                            className="w-full flex flex-col rounded-xl shadow-md overflow-hidden"
                            target="_blank"
                          >
                            <figure className="w-full h-52 overflow-hidden">
                              <img
                                alt="property-view"
                                src={property.images[0]}
                                width={400}
                                height={300}
                                className=" w-full h-full object-cover"
                              />
                            </figure>
                            <div className="flex-1 flex flex-col gap-3 justify-between pt-3">
                              <div className="flex gap-2 items-end px-3">
                                <div className="flex flex-col gap-1 flex-1">
                                  <h3 className="text-lg font-semibold text-zinc-900">
                                    An Amazing View among Trees
                                  </h3>
                                  <p className="text-zinc-600 text-sm">Greenwich, CT</p>
                                </div>
                                <p className="text-zinc-600 text-sm">$350/night</p>
                              </div>
                              <div className="bg-content2 p-3 flex flex-col gap-2">
                                <div className="flex items-center justify-between gap-2 text-sm text-zinc-900">
                                  <span>4 Bed</span>
                                  <span>3 Bath</span>
                                  <span>2,800 Sq Ft</span>
                                </div>
                              </div>
                            </div>
                          </Link>
                    ))}  
                    </div>
                     */}
                {/* Airbnb syle */}
                {loading ? (
                  <div className="w-full flex justify-center">
                    <LoaderCircle className="animate-spin" size={50} />
                  </div>
                ) : notFound ? (
                  <div className="w-full flex justify-center text-center text-gray-500 mt-8">
                    <p>No properties found. Please try another search.</p>
                  </div>
                ) : (
                  <div className="grid grid-cols-3 pt-8 gap-6 max-w-screen-xl w-full mx-auto px-4 sm:px-6">
                    {filteredStays?.map((property: any, index: number) => (
                      <Link
                        to={`/rent/properties/${property.id}`}
                        className="w-full flex flex-col rounded-xl overflow-hidden"
                        key={index}
                      >
                        <figure className="w-full h-52 overflow-hidden rounded-xl">
                          <img
                            alt="property-view"
                            src={property.accommodation.photos[0].url}
                            width={400}
                            height={300}
                            className="w-full h-full object-cover"
                          />
                        </figure>
                        <div className="flex-1 flex flex-col gap-3 justify-between pt-3">
                          <div className="flex gap-2 items-end px-3">
                            <div style={{ fontSize: 12 }} className="flex flex-col gap-1 flex-1 pb-4">
                              <div className="flex justify-between">
                                <h3 className="font-semibold text-zinc-900">
                                  {property.accommodation.name}
                                </h3>
                                <div className="flex items-center gap-1">
                                  <FaStar size={12} />
                                  <h1>{property.accommodation.rating}</h1>
                                </div>
                              </div>
                              <div className="text-zinc-700" style={{ marginTop: -5 }}>
                                {property.accommodation.location.address.line_one}, {property.accommodation.location.address.city_name}
                              </div>
                              <div className="font-semibold">
                                {property.cheapest_rate_total_amount} {property.cheapest_rate_currency}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className='w-full'>
              <div className='flex-1 sticky top-[62px] bg-slate-200 max-md:hidden h-[calc(100vh-62px)] overflow-hidden'>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_PUBLIC_GOOGLE_MAP_KEY ?? "",
                  }}
                  zoom={10}
                  center={{
                    lat: coordinates.lat,
                    lng: coordinates.lng,
                  }}
                ></GoogleMapReact>
              </div>
            </div>
          </div>
        ) : (
          <div className='flex w-full'>
            <div className='flex-1'>
              {/*Default style
                    <div className='grid grid-cols-4 pt-8 gap-6 max-w-screen-xl w-full mx-auto px-4 sm:px-6'>
                        {data.map((property: any, index: number) => (
                            <Link
                            to={`/rent/properties/${property._id}`}
                            className="w-full flex flex-col rounded-xl shadow-md overflow-hidden"
                            target="_blank"
                          >
                            <figure className="w-full h-52 overflow-hidden">
                              <img
                                alt="property-view"
                                src={property.images[0]}
                                width={400}
                                height={300}
                                className=" w-full h-full object-cover"
                              />
                            </figure>
                            <div className="flex-1 flex flex-col gap-3 justify-between pt-3">
                              <div className="flex gap-2 items-end px-3">
                                <div className="flex flex-col gap-1 flex-1">
                                  <h3 className="text-lg font-semibold text-zinc-900">
                                    An Amazing View among Trees
                                  </h3>
                                  <p className="text-zinc-600 text-sm">Greenwich, CT</p>
                                </div>
                                <p className="text-zinc-600 text-sm">$350/night</p>
                              </div>
                              <div className="bg-content2 p-3 flex flex-col gap-2">
                                <div className="flex items-center justify-between gap-2 text-sm text-zinc-900">
                                  <span>4 Bed</span>
                                  <span>3 Bath</span>
                                  <span>2,800 Sq Ft</span>
                                </div>
                              </div>
                            </div>
                          </Link>
                    ))}
                        
                    </div>
                    */}

              {/* Airbnb syle */}
              {loading ? (
                <div className="w-full flex justify-center">
                  <LoaderCircle className="animate-spin" size={50} />
                </div>
              ) : notFound ? (
                <div className="w-full flex justify-center text-center text-gray-500 mt-8">
                  <p>No properties found. Please try another search.</p>
                </div>
              ) : (
                <div className="grid grid-cols-4 pt-8 gap-6 max-w-screen-xl w-full mx-auto px-4 sm:px-6">
                  {filteredStays?.map((property: any, index: number) => (
                    <Link
                      to={`/rent/properties/${property.id}`}
                      className="w-full flex flex-col rounded-xl overflow-hidden"
                      key={index}
                    >
                      <figure className="w-full h-52 overflow-hidden rounded-xl">
                        <img
                          alt="property-view"
                          src={property.accommodation.photos[0].url}
                          width={400}
                          height={300}
                          className="w-full h-full object-cover"
                        />
                      </figure>
                      <div className="flex-1 flex flex-col gap-3 justify-between pt-3">
                        <div className="flex gap-2 items-end px-3">
                          <div style={{ fontSize: 12 }} className="flex flex-col gap-1 flex-1 pb-4">
                            <div className="flex justify-between">
                              <h3 className="font-semibold text-zinc-900">
                                {property.accommodation.name}
                              </h3>
                              <div className="flex items-center gap-1">
                                <FaStar size={12} />
                                <h1>{property.accommodation.rating}</h1>
                              </div>
                            </div>
                            <div className="text-zinc-700" style={{ marginTop: -5 }}>
                              {property.accommodation.location.address.line_one}, {property.accommodation.location.address.city_name}
                            </div>
                            <div className="font-semibold">
                              {property.cheapest_rate_total_amount} {property.cheapest_rate_currency}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        <div className='flex w-full justify-center pb-4'>
          <Pagination
            variant="bordered"
            radius="full"
            size={"md"}
            showControls
            classNames={{ item: 'bg-white' }}
            initialPage={1}
            total={1}
          />
        </div>

      </div>
      <Footer />
    </div>
  )
}

function FiltersModel() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleClose = () => {
    onOpenChange();
  };

  return (
    <>
      <Button
        onPress={onOpen}
        color="primary"
        radius="full"
        size="sm"
        className="max-w-20"
        fullWidth
      >
        Filters
      </Button>
      <Modal isOpen={isOpen} size='4xl' onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody>
               <BookingFilters onClose={handleClose} ></BookingFilters>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
export default PropertiesPage