
import { Accordion, AccordionItem } from "@nextui-org/accordion";
import faqsData from "../../data/frequent-ask-question.json"
import { Plus } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const FAQPage = () => {
  return (
    <div className="bg-white flex flex-col h-screen w-full justify-between">
        <Navbar/>
        <div className='h-full w-full max-w-4xl items-center px-4 py-8  mx-auto sm:px-6 flex flex-col gap-16 text-md'>
            <h1 className="pb-2 w-full text-center text-black text-2xl font-bold pt-12">
                Frequently Asked Questions
            </h1>
            <Accordion
            fullWidth
            keepContentMounted
            itemClasses={{
                base: "px-0 md:px-2 md:px-6",
                title: "font-medium",
                trigger: "py-6 flex-row-reverse",
                content: "pt-0 pb-6 text-base text-default-500",
                indicator: "rotate-0 data-[open=true]:-rotate-45",
            }}
            items={faqsData.tenant}
            selectionMode="multiple"
            >
            {faqsData.tenant.map((item, i) => (
                <AccordionItem
                key={i}
                indicator={<Plus size={28} color="#18181b" strokeWidth={2.5} />}
                title={item.title}
                >
                {item.content}
                </AccordionItem>
            ))}
            </Accordion>
        </div>
        <Footer/>
    </div>
  )
}

export default FAQPage