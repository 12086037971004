import React, { useState } from 'react'
import {  
    Dropdown, 
    DropdownTrigger, 
    DropdownMenu, 
    DropdownItem
} from "@nextui-org/dropdown";
import Login from './Login';
import Register from './Register';
import { Link } from "react-router-dom";

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);

    const handleAction = (key:any) => {
        if (key === "login") {
            setIsLoginOpen(true);
        }
        if (key === "register") {
            setIsRegisterOpen(true);
        }
    };

    return (
        <div className='sticky top-0 h-16 flex flex-row justify-between items-start px-5 py-5 bg-black text-white z-10'>
            <Link to={"/chat"} className='z-6 text-sm cursor-pointer text-center ml-5 hover:text-gray-100 transition-all'>
                Chat
            </Link>
            <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
                <Dropdown className='bg-black w-44 translate-x-3' isOpen={open}>
                    <DropdownTrigger>
                        <div className='z-6 cursor-pointer m-auto text-center text-sm mr-5'>Account</div>
                    </DropdownTrigger>
                    <DropdownMenu 
                        variant="light" 
                        className="z-10 bg-black w-full"
                        onAction={handleAction}
                    >
                        <DropdownItem className='text-white cursor-pointer mt-2 mb-2 bg-black' key="login">
                            <div className='w-full'>Login</div>
                        </DropdownItem>
                        <DropdownItem className='text-white cursor-pointer mt-2 mb-2 bg-black' key="register">
                            <div className='w-full'>Register</div>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
            {isLoginOpen && <Login isOpen={isLoginOpen} onClose={() => setIsLoginOpen(false)} />}
            {isRegisterOpen && <Register isOpen={isRegisterOpen} onClose={() => setIsRegisterOpen(false)} />}
        </div>
    )
}

export default Navbar;
