import React from 'react';
import { Link } from "react-router-dom";


const Footer = () => {
    const footerLinks = [
        { text: "Company", link: "/about" },
        { text: "Learn", link: "/frequent-ask-questions" },
        { text: "Pricing", link: "/pricing" },
        { text: "Legal", link: "/legal" },
        { text: "Contact", link: "/contact" },
    ];

    return (
        <footer className="mx-auto sm:px-6 !max-w-full grid w-full bottom-0 bg-content1 dark z-10 items-center justify-center">
                <div className="flex gap-5 h-[72px] justify-center items-center py-[14px]">
                    {footerLinks.map((e, key) => (
                        <Link
                            key={key}
                            color="foreground"
                            to={e.link}
                            className="text-white hover:text-gray-100 text-sm transition-colors"
                        >
                            {e.text}
                        </Link>
                    ))}
                </div>
        </footer>
    );
};

export default Footer;
