import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import React from 'react'
import {Tabs, Tab} from "@nextui-org/tabs";

const PricingPage = () => {
  return (
    <div className='bg-white flex flex-col h-screen w-full justify-between'>
        <Navbar/>
        <div className='h-full w-full !max-w-3xl items-center px-4 py-24 pt-16 mx-auto sm:px-6 flex flex-col gap-8 text-md'>
            <h1 className="text-4xl font-bold  text-zinc-900">Our Pricing</h1>
            <Tabs
                color="primary"
                size="lg"
                aria-label="Tabs colors"
                radius="lg"
                fullWidth
                className="max-w-80"
                >
                <Tab key="tenant" title="Tenant">
                    <div className="flex flex-col text-center mb-8">
                        <h2 className="text-xl font-bold text-zinc-900">
                        The tenant is required to pay a rental service fee.
                        </h2>
                    </div>
                    <div className="flex justify-center items-center gap-6 w-full">
                        <div className='bg-black/90 capitalize text-base/7 text-center text-white font-normal w-56 border-2 border-divider rounded-lg p-4'>
                            If The Rental Is
                            <span className="block font-bold">Short Term</span>
                            The Fee Is <span className="font-bold">10%</span>
                        </div>
                        <div className='bg-black/90 capitalize text-base/7 text-center text-white font-normal w-56 border-2 border-divider rounded-lg p-4'>
                            If The Rental Is
                            <span className="block font-bold">Mid Term</span>
                            The Fee Is <span className="font-bold">7.5%</span>
                        </div>
                        <div className='bg-black/90 capitalize text-base/7 text-center text-white font-normal w-56 border-2 border-divider rounded-lg p-4'>
                            If the rental Is
                            <span className="block font-bold">Long Term</span>
                            The Fee Is <span className="font-bold">5%</span>
                        </div>
                    </div>
                </Tab>
                <Tab key="landlord" title="Landlord">
                    <div className="flex flex-col text-center mb-8">
                        <h2 className="text-xl font-bold text-zinc-900">
                        The landlord is required to pay a payments processing fee.
                        </h2>
                    </div>
                    <div className="flex justify-center items-center gap-6 w-full">
                        <div className='bg-black/90 capitalize text-base/7 text-center text-white font-normal w-56 border-2 border-divider rounded-lg p-4'>
                            If The Payment Is
                            <span className="block font-bold">Debit Card</span>
                            The Fee Is <span className="font-bold">3%</span>
                        </div>
                        <div className='bg-black/90 capitalize text-base/7 text-center text-white font-normal w-56 border-2 border-divider rounded-lg p-4'>
                            If The Payment Is
                            <span className="block font-bold">Credit Card</span>
                            The Fee Is <span className="font-bold">3%</span>
                        </div>
                        <div className='bg-black/90 capitalize text-base/7 text-center text-white font-normal w-56 border-2 border-divider rounded-lg p-4'>
                            If The Payment Is
                            <span className="block font-bold">ACH</span>
                            The Fee Is <span className="font-bold">1%</span>
                        </div>
                    </div>
                </Tab>
                </Tabs>
        </div>
        <Footer/>
    </div>
  )
}

export default PricingPage