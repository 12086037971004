import type { Filter } from "./filters-types";
import { FilterTypeEnum } from "./filters-types";
import useFilterStore from "./useFilterStore";

const getBookingItems = (): Filter[] => {
  
  const { priceRange } = useFilterStore.getState();

  return [
    {
      title: "Price Range",
      description: "Total prices for 5 nights including fees and taxes",
      type: FilterTypeEnum.PriceRange,
      range: {
        min: 0,
        defaultValue: priceRange, 
        max: 10000,
        step: 1,
      },
    },
    {
      title: "Property Rating",
      description: "Includes Acme House listings",
      type: FilterTypeEnum.Rating,
    },
    {
      title: "Amenities",
      description: "Select as many as you want",
      type: FilterTypeEnum.TagGroup,
      options: [
        {
          title: "Business Centre",
          value: "business_centre",
          icon: "lucide:briefcase-business",
        },
        {
          title: "Room Service",
          value: "room_service",
          icon: "ic:outline-room-service",
        },
        {
          title: "Lounge",
          value: "lounge",
          icon: "game-icons:sofa",
        },
        {
          title: "24 Hour Front Desk",
          value: "24_hour_front_desk",
          icon: "mdi:clock",
        },
        {
          title: "Accessibility Mobility",
          value: "accessibility_mobility",
          icon: "uil:wheelchair-alt",
        },
        {
          title: "Pool",
          value: "pool",
          icon: "solar:swimming-bold",
        },
        {
          title: "Parking",
          value: "parking",
          icon: "fa-solid:car",
        },
        {
          title: "Wifi",
          value: "wifi",
          icon: "solar:home-wifi-angle-bold",
        },
        {
          title: "Laundry",
          value: "laundry",
          icon: "solar:washing-machine-bold",
        },
        {
          title: "Cash Machine",
          value: "cash_machine",
          icon: "streamline:bag-dollar-solid",
        },
        {
          title: "Concierge",
          value: "concierge",
          icon: "lucide:handshake",
        },
        {
          title: "Spa",
          value: "spa",
          icon: "solar:bath-bold",
        },
        {
          title: "Restaurant",
          value: "restaurant",
          icon: "icon-park-solid:fork-spoon",
        },
        {
          title: "Gym",
          value: "gym",
          icon: "solar:treadmill-bold",
        },
        {
          title: "Pets Allowed",
          value: "pets_allowed",
          icon: "solar:cat-bold",
        },
      ],
    },
    {
      title: "Booking Options",
      description: "Customize your stay",
      type: FilterTypeEnum.Toggle,
      options: [
        {
          title: "Instant Book",
          description: "Book without waiting for host approval",
          value: "instant_book",
        },
        {
          title: "Superhost",
          description: "Stay with recognized hosts and enjoy a 5-star stay",
          value: "superhost",
        },
        {
          title: "Free Cancellation",
          description:
            "Cancel before 4:00 PM on 10/15 and get a full refund, minus the first night and service fee",
          value: "free_cancellation",
        },
        {
          title: "Self Check-in",
          description: "Easy access to the property once you arrive",
          value: "self_checkin",
        },
      ],
    },
  ];
};

export default getBookingItems;
