import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import { Input, Textarea } from "@nextui-org/input";
import React, { useState, useEffect } from 'react'
import { useDisclosure } from "@nextui-org/react";
import { Button } from "@nextui-org/button";
import { Select, SelectItem } from "@nextui-org/select";
import { User } from "@nextui-org/user";
import {Avatar} from "@nextui-org/avatar";
import { Divider } from "@nextui-org/divider";
import GoogleMapReact from "google-map-react"
import { Modal, ModalContent, ModalBody } from "@nextui-org/modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Zoom } from "swiper/modules";
import { FaSwimmer, FaParking, FaWifi, FaConciergeBell, FaUtensils, FaDumbbell, FaDog, FaUsers, FaBed, FaBath, FaCar, FaStar } from 'react-icons/fa';
import { MdOutlineBusinessCenter, MdOutlineRoomService, MdOutlineWheelchairPickup, MdOutlineDesk, MdOutlineLan, MdOutlineSpa, MdOutlineLocalAtm, MdRestaurant, MdKeyboardDoubleArrowDown } from 'react-icons/md';

import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    Calendar,
} from "@nextui-org/react";
import { ChevronDown, Plus, Minus, MapPin, Phone, LoaderCircle } from 'lucide-react';
import { parseDate } from "@internationalized/date";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import axios from 'axios';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/zoom";

type Amenities = {
    type: string, 
    description: string
}

const reviews = [
    {
        name: "Bessie Cooper",
        date: "12 March 2022",
        avatar: "",
        rating: 3,
        message:
            "The second bedroom is a corner room with double windows. The kitchen has fabulous space, new appliances, and a laundry area. Other features include rich herringbone floors",

    },
    {
        name: "Bessie Cooper",
        date: "12 March 2022",
        avatar: "https://i.pravatar.cc/150?u=a04258114e29026702d",
        rating: 4,
        message:
            "The second bedroom is a corner room with double windows. The kitchen has fabulous space, new appliances, and a laundry area. Other features include rich herringbone floors",
    },
];




type Items = {
    name: string,
    description: string,
    count: number
};

const PropertyPage = () => {
    const { id } = useParams<{ id: string }>();
    const [checkIn, setCheckIn] = useState("");
    const [checkOut, setCheckOut] = useState("");
    const [isCheckInOpen, setIsCheckInOpen] = useState(false);
    const [isCheckOutOpen, setIsCheckOutOpen] = useState(false);
    const [isGuestsOpen, setIsGuestsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const [address, setAddress] = useState([
        {
          title: "State:",
          text: '--',
        },
        {
          title: "County:",
          text: '--',
        },
        {
          title: "City:",
          text: '--',
        },
        {
          title: "Zip Code:",
          text: '--',
        },
      ]);



    const getCalenderValue = (data: any) => data ? parseDate(format(new Date(data), "yyyy-MM-dd")) : null;
    const getInputValue = (data: any) => data ? format(new Date(data), "MM. dd. yyyy") : "";


    const [items, setItems] = useState<Items[]>([
        { name: 'Adults', description: 'Ages 13 or above', count: 0 },
        { name: 'Children', description: 'Ages 2-12', count: 0 },
        { name: 'Infants', description: 'Under 2', count: 0 },
        { name: 'Pets', description: 'Service animal?', count: 0 },
    ]);

    const iconMap: { [key: string]: JSX.Element } = {
        business_centre: <MdOutlineBusinessCenter />,
        room_service: <MdOutlineRoomService />,
        lounge: <FaConciergeBell />,
        "24_hour_front_desk": <MdOutlineDesk />,
        accessibility_mobility: <MdOutlineWheelchairPickup />,
        pool: <FaSwimmer />,
        parking: <FaParking />,
        wifi: <FaWifi />,
        laundry: <MdOutlineLan />,
        cash_machine: <MdOutlineLocalAtm />,
        concierge: <FaConciergeBell />,
        spa: <MdOutlineSpa />,
        restaurant: <MdRestaurant />,
        gym: <FaDumbbell />,
        pets_allowed: <FaDog />,
    }



    const getGuestSummary = () => {
        const adultsCount = items.find(item => item.name === 'Adults')?.count || 0;
        const childrenCount = items.find(item => item.name === 'Children')?.count || 0;
        const infantsCount = items.find(item => item.name === 'Infants')?.count || 0;

        let summary = '';
        if (adultsCount > 0) summary += `${adultsCount} guest${adultsCount > 1 ? 's' : ''}`;
        if (childrenCount > 0) summary += `${summary ? ', ' : ''}${childrenCount} child${childrenCount > 1 ? 'ren' : ''}`;
        if (infantsCount > 0) summary += `${summary ? ', ' : ''}${infantsCount} infant${infantsCount > 1 ? 's' : ''}`;

        return summary || "";
    };

    
    const [price, setPrice] = useState("");
    const [currency, setCurrency] = useState("")
    const [loc, setLoc] = useState("")
    const [desc, setDesc] = useState("")
    const [img1, setImg1] = useState("")
    const [img2, setImg2] = useState("")
    const [img3, setImg3] = useState("")
    const [photoUrls, setPhotoUrls] = useState<string[]>([])
    const [guestCount, setGuestCount] = useState("")
    const [roomCount, setRoomCount] = useState("")

    const [longitude, setLongitude] = useState(-84.506836)
    const [latitude, setLatitude] = useState(44.182205)

    const [amenities, setAmenities] = useState([])

    async function searchStayById() {
        setLoading(true);
        try {
            const response = await axios.post('http://89.116.50.93:5000/api/stays/stay', { id }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });

            console.log(response.data);

            
            setImg1(response.data.data.accommodation.photos[0].url);
            setImg2(response.data.data.accommodation.photos[1].url);
            setImg3(response.data.data.accommodation.photos[2].url);
            const images = response.data.data.accommodation.photos.map((photo: { url: string }) => photo.url);
            setPhotoUrls(images.slice(0, 3));
            setPrice(response.data.data.cheapest_rate_total_amount);
            setCurrency(response.data.data.cheapest_rate_currency);
            setLoc(response.data.data.accommodation.location.address.line_one + ", " + response.data.data.accommodation.location.address.city_name);
            setDesc(response.data.data.accommodation.description);
            setGuestCount(response.data.data.guests.length);
            setRoomCount(response.data.data.rooms);
            setLongitude(response.data.data.accommodation.location.geographic_coordinates.longitude);
            setLatitude(response.data.data.accommodation.location.geographic_coordinates.latitude);
            setAmenities(response.data.data.accommodation.amenities)

            setAddress([
                {
                  title: "Region:",
                  text: response.data.data.accommodation.location.address.region ?? '--',
                },
                {
                  title: "County:",
                  text: response.data.data.accommodation.location.address.country_code ?? '--',
                },
                {
                  title: "City:",
                  text: response.data.data.accommodation.location.address.city_name ?? '--',
                },
                {
                  title: "Zip Code:",
                  text: response.data.data.accommodation.location.address.postal_code ?? '--',
                },
              ]);
        }   catch (error:any) {
            if (error.response) {
              console.error('Error response:', error.response.data);
              console.error('Error status:', error.response.status);
            } else if (error.request) {
              console.error('No response received:', error.request);
            } else {
              console.error('Error message:', error.message);
            }
            console.error('Complete error object:', error);
          } finally {
            setLoading(false);
          }
    }

    useEffect(() => {
        const fetchData = async () => {
            await searchStayById();
        };

        fetchData();
    }, [id]);


    const handleIncrement = (index: number) => {
        setItems(prevItems =>
            prevItems.map((item, i) =>
                i === index ? { ...item, count: item.count + 1 } : item
            )
        );
    };

    const handleDecrement = (index: number) => {
        setItems(prevItems =>
            prevItems.map((item, i) =>
                i === index ? { ...item, count: Math.max(0, item.count - 1) } : item
            )
        );
    };

    console.log(amenities)
    return (
        <div className="bg-[#F7F7F7] flex flex-col h-full w-full justify-between">
            <Navbar />
            
            <div className='w-full relative grid pt-6'>
            {loading ? (
                <div className="flex justify-center h-screen">
                    <LoaderCircle className= "animate-spin" size={50}/>
                </div>
            ) : (
                <div style={{ width: 1100, margin: '0 auto' }}>
                    
                    {/*Property Image*/}
                    <div className='m-auto py-6 px-6 border rounded-xl bg-white'>
                        <div
                            className="relative w-full rounded-xl cursor-pointer"
                            onClick={onOpen}
                        >
                            <div className="grid" style={{ gridTemplateColumns: "725px 350px", gridGap: 25, width: 1100, height: 600 }}>
                                <div className=" py-2.5 px-5 rounded-md bg-white absolute top-4 right-4">
                                    <p className=" font-bold text-sm text-zinc-900">
                                        All Photos
                                    </p>
                                </div>
                                <div>
                                    <img src={img1} style={{ width: 725, height: 600, objectFit: 'cover' }} alt="property-image" className='rounded-xl'/>
                                </div>
                                <div style={{ display: 'grid', gap: 25 }}>
                                    <img src={img2} style={{ width: 300, height: 285, objectFit: 'cover' }} alt="property-image2" className='rounded-xl' />
                                    <img src={img3} style={{ width: 300, height: 285, objectFit: 'cover' }} alt="property-image3" className='rounded-xl' />
                                </div>
                            </div>

                            <Modal
                                size="full"
                                isOpen={isOpen}
                                onOpenChange={onOpenChange}
                                backdrop="blur"
                                classNames={{
                                    closeButton: "bg-white z-20",
                                }}
                            >
                                <ModalBody>
                                    <ModalContent className="bg-transparent bg-opacity-25 pr-4">
                                        <Swiper
                                            zoom={true}
                                            navigation={true}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[Zoom, Navigation, Pagination]}
                                            spaceBetween={24}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            {photoUrls.map((e, key) => (
                                                <SwiperSlide key={key}>
                                                    <div className="swiper-zoom-container">
                                                        <img
                                                            src={e}
                                                            alt={`property-${key}`}
                                                            width={1000}
                                                            height={1000}
                                                            className="w-full h-full object-cover"
                                                        />
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </ModalContent>
                                </ModalBody>
                            </Modal>
                        </div>
                        {/*Property Details*/}
                        <div className="flex flex-col gap-4 w-full pt-6 ml-2 pr-4">
                            <div className='flex'>
                                <div className='flex flex-col gap-4 w-full pr-4'>
                                    <div className='flex gap-2 items-center ml-1'>
                                        <h4 className='text-2xl font-bold'>{currency} {price}</h4>
                                        <h4 className='text-sm text-neutral-700'>per night</h4>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <MapPin size={20}/>
                                        <h4 className='text-md'>{loc}</h4>
                                    </div>
                                    <div className='flex gap-10 ml-1 mr-1 mt-1 pb-2'>
                                        <div className='flex gap-2 items-center'>
                                            <FaUsers size={18}/>
                                            <h4 className='text-sm'>{guestCount} Guests</h4>  
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <FaBed size={20}/>
                                            <h4 className='text-sm'>{roomCount} Bedrooms</h4>
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <FaBath size={15}/>
                                            <h4 className='text-sm'>1 Baths</h4>
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <FaCar size={16}/>
                                            <h4 className='text-sm'>Free Parking</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-end items-end'>
                                    <div className='pb-2'>
                                        <Button className='rounded-lg' color='primary'>
                                            <Phone size={20} className='text-white fill-current'/>
                                            <h4 className='text-white'>Rent this Property</h4>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            
                                                        <Divider/>
                            <div className='flex flex-col gap-2 ml-1 pt-2 pb-4'>
                                <h1 className='text-xl font-bold'>Property Information</h1>
                                <h4 className='text-md text-justified text-neutral-700'>
                                {desc}
                                </h4>
                            </div>

                        </div>
                    </div>
                    {/*Cards*/}
                    <div className="grid grid-cols-3 py-6 gap-6 m-auto">
                        <div className="flex flex-col gap-6 flex-1 col-span-2">
                            {/*Property Detail Overview*/}
                            {/*
                            <div className=" p-[30px] flex flex-col gap-[30px] bg-white rounded-xl">
                                <h4 className="text-lg text-zinc-900 font-bold">
                                    Property Details
                                </h4>
                                <div className="grid grid-cols-3 gap-12">
                                    
                                    <div className="flex items-center gap-[15px]">
                                        <div className="icon-container">
                                            <FaUsers />
                                        </div>
                                        <div className="flex flex-col flex-1">
                                            <h6 style={{ fontSize: "15px" }}
                                                className=" font-bold text-zinc-900" >Guest Capacity</h6>
                                            <p style={{ fontSize: 13 }}>2 Adults, 2 Children</p>
                                        </div>
                                    </div>

                                    
                                    <div className="flex items-center gap-[15px]">
                                        <div className="icon-container">
                                            <FaBed />
                                        </div>
                                        <div className="flex flex-col flex-1">
                                            <h6 style={{ fontSize: "15px" }}
                                                className=" font-bold text-zinc-900" >Bedrooms</h6>
                                            <p style={{ fontSize: 13 }}>2 Bedrooms</p>
                                        </div>
                                    </div>

                                    
                                    <div className="flex items-center gap-[15px]">
                                        <div className="icon-container">
                                            <FaBath />
                                        </div>
                                        <div className="flex flex-col flex-1">
                                            <h6 style={{ fontSize: "15px" }}
                                                className=" font-bold text-zinc-900" >Bathrooms</h6>
                                            <p style={{ fontSize: 13 }}>1.5 Bathrooms</p>
                                        </div>
                                    </div>

                                    
                                    <div className="flex items-center gap-[15px]">
                                        <div className="icon-container">
                                            <FaClock />
                                        </div>
                                        <div className="flex flex-col flex-1">
                                            <h6 style={{ fontSize: "15px" }}
                                                className=" font-bold text-zinc-900" >Check in/out</h6>
                                            <p style={{ fontSize: 13 }}>3:00 PM - 11:00 AM</p>
                                        </div>
                                    </div>

                                    
                                    <div className="flex items-center gap-[15px]">
                                        <div className="icon-container">
                                            <FaCar />
                                        </div>
                                        <div className="flex flex-col flex-1">
                                            <h6 style={{ fontSize: "15px" }}
                                                className=" font-bold text-zinc-900" >Parking</h6>
                                            <p style={{ fontSize: 13 }}>Free On-Site Parking</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}
                            {/*Property Description*/}
                            {/*
                            <div className=" p-[30px] flex flex-col gap-6 bg-white rounded-xl">
                                <h4 className="text-lg text-zinc-900 font-bold">
                                    Property Description
                                </h4>
                                <div className="flex flex-col gap-[10px]">
                                    <h6 className=" leading-[26px] text-sm text-zinc-900">
                                        {data?.propertyDetails?.details}
                                    </h6>
                                </div>
                            </div>
                            */}

                            {/*Property Amenities*/}
                            <div className="p-[30px] flex flex-col gap-6 bg-white rounded-xl">
                                <h4 className="text-lg text-zinc-900 font-bold">Property Amenities</h4>
                                <div className="flex flex-wrap gap-3">
                                    <div className="space-y-8">
                                        <div className="grid grid-cols-4 gap-5">
                                            {amenities.map((amenity:Amenities) => (
                                                <div
                                                    key={amenity.type}
                                                    style={{ maxHeight: 100, minHeight: 100 }}
                                                    className={`cursor-pointer flex-1 rounded-lg p-4 flex flex-col items-center justify-center text-center transition-all duration-200 ease-in-out
                                                        border-2 border-black`}
                                                >
                                                    <span className="text-2xl mb-2">
                                                        {iconMap[amenity.type] ?? <MdOutlineRoomService />}
                                                    </span>
                                                    <span className="text-small">{amenity.description}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Porperty Location*/}
                            <div className=" p-[30px] flex flex-col gap-6 bg-white rounded-xl">
                                <h4 className="text-lg text-zinc-900 font-bold">
                                    Property Location
                                </h4>
                                <div className="flex gap-3">
                                    {address.map((e, key) => {
                                        return (
                                            <div key={key} className="flex items-center flex-1 gap-2">
                                                <h6 className=" text-zinc-900 text-sm font-bold">
                                                    {e.title}
                                                </h6>
                                                <p className="text-sm text-zinc-900">{e.text}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="w-full h-[300px] rounded-xl overflow-hidden relative">
                                    <GoogleMapReact
                                        bootstrapURLKeys={{
                                            key: process.env.REACT_APP_PUBLIC_GOOGLE_MAP_KEY ?? "",
                                        }}
                                        defaultZoom={11}
                                        defaultCenter={{
                                            lat: latitude || 44.182205,
                                            lng: longitude || -84.506836,
                                        }}
                                    />
                                </div>
                            </div>
                            {/*Property Reviews*/}
                            <div className="p-[30px] pt-6 flex flex-col gap-[30px] bg-white rounded-xl">
                                <div className="text-lg text-zinc-900 gap-[5px] items-center flex">
                                    <h4 className='font-semibold'>Reviews</h4>
                                    <FaStar className='text-yellow-300'/>
                                    <div className="flex gap-1 flex-1">
                                        <h4 className='font-semibold'>5.0</h4>
                                        <h4 className='text-md text-neutral-500'>(based on 3 reviews)</h4>
                                    </div>
                                    <p className="text-neutral-500 text-sm leading-relaxed">Sort by</p>
                                    <Select
                                        variant="flat"
                                        labelPlacement="outside-left"
                                        defaultSelectedKeys={"1"}
                                        className="max-w-32"
                                    >
                                        <SelectItem key={"1"} value={"1"}>
                                            Newest
                                        </SelectItem>
                                        <SelectItem key={"2"} value={"2"}>
                                            Oldest
                                        </SelectItem>
                                        <SelectItem key={"3"} value={"3"}>
                                            High Rating
                                        </SelectItem>
                                        <SelectItem key={"4"} value={"4"}>
                                            Low Rating
                                        </SelectItem>
                                    </Select>
                                </div>
                                {reviews.map((e, key) => {
                                    return (
                                        <>
                                            <div className="flex flex-col gap-5">
                                                <div className="flex justify-between gap-2">
                                                    <User
                                                        name={e.name}
                                                        description={e.date}
                                                        className="w-max"
                                                        classNames={{
                                                            name: "text-base font-semibold",
                                                            description: "text-sm",
                                                            wrapper: "gap-1",
                                                            base: "gap-4",
                                                        }}
                                                        avatarProps={{
                                                            size: "lg",
                                                            isBordered: true,
                                                            src: e.avatar,
                                                        }}
                                                    />
                                                    <div className="flex items-center text-base gap-1">
                                                        <FaStar
                                                            className={
                                                                e.rating > 0 ? "text-yellow-300" : "text-gray-300"
                                                            }
                                                        />
                                                        <FaStar
                                                            className={
                                                                e.rating > 1 ? "text-yellow-300" : "text-gray-300"
                                                            }
                                                        />
                                                        <FaStar
                                                            className={
                                                                e.rating > 2 ? "text-yellow-300" : "text-gray-300"
                                                            }
                                                        />
                                                        <FaStar
                                                            className={
                                                                e.rating > 3 ? "text-yellow-300" : "text-gray-300"
                                                            }
                                                        />
                                                        <FaStar
                                                            className={
                                                                e.rating > 4 ? "text-yellow-300" : "text-gray-300"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <aside className="text-sm text-zinc-900">{e.message}</aside>
                                            </div>
                                            {key < reviews.length - 1 && <Divider />}
                                        </>
                                    );
                                })}
                                <Button
                                    color="primary"
                                    variant="ghost"
                                    size="lg"
                                    className="w-max"
                                    endContent={<MdKeyboardDoubleArrowDown className="text-2xl" />}
                                >
                                    Show more
                                </Button>
                            </div>
                            {/*Meet the Host*/}
                        <div className="bg-white rounded-lg p-6 shadow-sm">
                                <h2 className="text-2xl font-semibold mb-4">Meet the Host</h2>
                                <div className="flex gap-5 pt-4">
                                    <div className='w-2/3 py-6 rounded-lg shadow-lg px-6'>
                                        <div className='flex items-center justify-center w-full pt-4'>
                                            <Avatar src="https://i.pravatar.cc/150?u=a042581f4e29026024d" size='lg' />
                                        </div>
                                        <div className='flex flex-col items-center justify-center w-full pt-4'>
                                            <h1 className='text-md font-bold'>Jane Doe</h1>
                                            <h3 className='text-sm text-neutral-500'>Real Estate Manager</h3>
                                        </div>
                                        <div className='w-full flex flex-row justify-between pt-10'>
                                            <div className='flex flex-col items-center justify-center'>
                                                <h1 className='text-base'>458</h1>
                                                <h3 className='text-xs text-neutral-500'>Reviews</h3>
                                            </div>
                                                <Divider orientation='vertical'/>
                                            <div className='flex flex-col items-center justify-center'>
                                                <div className='flex items-center justify-center gap-0.5'>
                                                    
                                                    <FaStar size={12}/>
                                                    <h1 className='text-base'>4.5</h1>
                                                </div>
                                                <h3 className='text-xs text-neutral-500'>Rating</h3>
                                            </div>
                                                <Divider orientation='vertical'/>
                                            <div className='flex flex-col items-center justify-center'>
                                                <h1 className='text-base'>5</h1>
                                                <h3 className='text-xs text-neutral-500'>Experience</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='h-full w-full mt-4'>
                                            <div className='flex flex-col w-full gap-6'>
                                                <div className='w-full'>
                                                    <h1 className='text-md font-bold'>About Jane Doe</h1>
                                                    <h3 className='text-xs text-neutral-500 text-justify'>lorem ipsum dolor sit amet,  cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h3>
                                                </div>
                                                <div className='w-full'>
                                                    <h1 className='text-md font-bold'>Host Details</h1>
                                                    <div className='flex items-center gap-2'>
                                                        <h3 className='text-sm text-neutal-700'>Response rate: </h3>
                                                        <h3 className='text-sm text-neutral-500'> 100% </h3>
                                                    </div>
                                                    <h3 className='text-sm text-neutal-700'>Responds within an hour</h3>
                                                </div>
                                                <div className='w-full flex justify-end pr-2'>
                                                    <Button className='rounded-lg' color='primary'>Message Host</Button>
                                                </div>
                                            </div>
                                    </div>
                                </div>

                            </div> 
                        </div>
                        
                       

                        {/*Side Card*/}
                        <div className="flex flex-col gap-6">
                            {/* <SideImage data={propertyDetail.data} /> */}
                            <div className='bg-white sticky top-[88px] gap-3 rounded-xl shadow p-4 py-8 px-4 h-max flex flex-col'>
                                <div className="flex flex-col gap-2 items-center justify-center">
                                    <h6 className="text-zinc-900 text-2xl text-center font-bold">
                                        Rent This House
                                    </h6>

                                    {/*Check In*/}
                                    <div className="grid  w-full">
                                        <div className="grid grid-cols-1 w-full">

                                            <Popover
                                                placement="bottom-start"
                                                color="secondary"
                                                className=''
                                                isOpen={isCheckInOpen}
                                                onOpenChange={(open) => setIsCheckInOpen(open)}
                                            >
                                                <PopoverTrigger>
                                                    <Input
                                                        radius='full'
                                                        size='sm'
                                                        label='Check in'
                                                        value={getInputValue(checkIn)}
                                                        classNames={{
                                                            input: ["text-left text-default-500"],
                                                            innerWrapper: "pb-0",
                                                            inputWrapper: "bg-white shadow-none"
                                                        }}
                                                        className=' h-full'
                                                        fullWidth
                                                        endContent={
                                                            <Button
                                                                size="sm"
                                                                radius="full"
                                                                variant="light"
                                                                className="text-base h-full"
                                                                style={{ minWidth: "auto", transition: "background-color 0.3s ease" }}
                                                                onClick={() => setIsCheckInOpen(true)}
                                                            >
                                                                <ChevronDown size={20} />
                                                            </Button>
                                                        }
                                                    />
                                                </PopoverTrigger>
                                                <PopoverContent className="p-0">
                                                    <Calendar
                                                        value={getCalenderValue(checkIn)}
                                                        onChange={(e: any) => setCheckIn(format(new Date(e), "yyyy-MM-dd"))}
                                                    />
                                                </PopoverContent>
                                            </Popover>
                                            {/*Check Out*/}
                                            <Popover
                                                placement="bottom-start"
                                                color="secondary"
                                                className=''
                                                isOpen={isCheckOutOpen}
                                                onOpenChange={(open) => setIsCheckOutOpen(open)}
                                            >
                                                <PopoverTrigger>
                                                    <Input
                                                        radius='full'
                                                        size='sm'
                                                        label='Check out'
                                                        value={getInputValue(checkOut)}
                                                        classNames={{
                                                            input: ["text-left text-default-500"],
                                                            innerWrapper: "pb-0",
                                                            inputWrapper: "bg-white shadow-none"
                                                        }}
                                                        className=' h-full'
                                                        fullWidth
                                                        endContent={
                                                            <Button
                                                                size="sm"
                                                                radius="full"
                                                                variant="light"
                                                                className="text-base h-full"
                                                                style={{ minWidth: "auto", transition: "background-color 0.3s ease" }}
                                                                onClick={() => setIsCheckOutOpen(true)}
                                                            >
                                                                <ChevronDown size={20} />
                                                            </Button>
                                                        }
                                                    />
                                                </PopoverTrigger>
                                                <PopoverContent className="p-0">
                                                    <Calendar
                                                        value={getCalenderValue(checkOut)}
                                                        onChange={(e: any) => setCheckOut(format(new Date(e), "yyyy-MM-dd"))}
                                                    />
                                                </PopoverContent>
                                            </Popover>
                                            {/*Guests*/}
                                            <Popover
                                                placement="bottom-start"
                                                color="secondary"
                                                className='h-full'
                                                isOpen={isGuestsOpen}
                                                onOpenChange={(open) => setIsGuestsOpen(open)}
                                            >
                                                <PopoverTrigger>
                                                    <Input
                                                        radius='full'
                                                        size='sm'
                                                        label='Guests'
                                                        value={getGuestSummary()}
                                                        fullWidth
                                                        classNames={{
                                                            input: ["text-left text-default-500"],
                                                            innerWrapper: "pb-0",
                                                            inputWrapper: "bg-white shadow-none"
                                                        }}
                                                        className='h-full'
                                                        endContent={
                                                            <Button
                                                                size="sm"
                                                                radius="full"
                                                                variant="light"
                                                                className="text-base h-full"
                                                                style={{ minWidth: "auto", transition: "background-color 0.3s ease" }}
                                                                onClick={() => setIsGuestsOpen(true)}
                                                            >
                                                                <ChevronDown size={20} />
                                                            </Button>
                                                        }
                                                    />
                                                </PopoverTrigger>
                                                <PopoverContent className="bg-white">
                                                    {items.map((item, index) => (
                                                        <div key={item.name} className="grid grid-cols-[150px_130px]  w-full justify-between items-center ml-5 mt-3 mb-3">
                                                            <div className="flex-1">
                                                                <div className="font-semibold">{item.name}</div>
                                                                <div style={{ color: "grey", fontSize: 14 }} className="text-sm text-muted-foreground">{item.description}</div>
                                                            </div>
                                                            <div className="grid grid-cols-[30px_50px_30px]  items-center">
                                                                <Button
                                                                    variant="flat"
                                                                    style={{ width: 30, minWidth: 30 }}
                                                                    className="h-8  rounded-full flex items-center p-0 justify-center"
                                                                    onClick={() => handleDecrement(index)}
                                                                    disabled={item.count === 0}
                                                                >
                                                                    <Minus className="h-4 w-4" />
                                                                </Button>
                                                                <span className="text-center">{item.count}</span>
                                                                <Button
                                                                    variant="flat"
                                                                    style={{ width: 30, minWidth: 30 }}
                                                                    className="h-8 bg-black text-white rounded-full flex items-center  p-0  justify-center"
                                                                    onClick={() => handleIncrement(index)}
                                                                >
                                                                    <Plus className="h-4 w-4" />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </PopoverContent>
                                            </Popover>

                                            {/*Message*/}
                                            <Textarea
                                                label="Message"
                                                minRows={4}
                                                maxRows={6}
                                                radius="full"
                                                size="sm"
                                                classNames={{
                                                    inputWrapper: "bg-white shadow-none pt-4",
                                                }}
                                            />

                                        </div>
                                    </div>

                                    <Button color="primary" fullWidth className='' >Request Rental</Button>
                                    <Button color="primary" fullWidth className='' >Book Now</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            </div>
            <Footer />
        </div>
    )
}

export default PropertyPage