import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SearchPage from '../components/Search';


const RentPage = () => {
  return (
    <div className="bg-black flex flex-col h-screen w-full justify-between">
        <Navbar/>
        <div
          style={{ marginTop: -100 }}
          className="absolute inset-x-0 left-1/2 top-1/2 right-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center  w-full h-full flex-1 z-10 max-w-4xl mx-auto px-4 sm:px-6"
        >
          <div className='h-1/4'>
            <img src="/logo.png" width={100} height={100} alt="about-picture" />
          </div>
          
          <SearchPage />
        </div>
        <Footer/>
    </div>
  );
};

export default RentPage;