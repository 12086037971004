import React from 'react'
import { Input, Textarea } from "@nextui-org/input";
import { Button } from "@nextui-org/button";
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const ContactPage = () => {
  return (
    <div className='bg-white flex flex-col h-screen w-full justify-between'>
      <Navbar/>
      <div className='h-full w-full items-center px-4 py-8 pt-12 mx-auto sm:px-6 flex flex-col gap-8 text-md'>
        <div className='flex justify-between h-full gap-96'>
          <div className='flex flex-col justify-center w-[500px] gap-4'>
            <h1 className='text-2xl font-semibold uppercase pb-3'>Contact us</h1>
            <Input
              name="name"
              label="Full Name"
              variant="underlined"
              classNames={{ innerWrapper: "pb-0" }}
            />
            <Input
              name="email"
              label="Email Address"
              variant="underlined"
              classNames={{ innerWrapper: "pb-0" }}
            />
            <Input
              name="phone"
              label="Phone Number"
              variant="underlined"
              classNames={{ innerWrapper: "pb-0" }}
            />
            <Textarea
              name="message"
              label="Enter Message"
              variant="underlined"
            />
            <Button
                type="submit"
                color="primary"
                variant="shadow"
                className="mt-3"
                size="lg"
              >
                Submit
              </Button>
          </div>

          <div className='flex items-center justify-center'>
            <img 
              src='/logo.png'
              height={200}
              width={200}
              alt="Logo"
              style={{ filter: 'invert(1)' }}
            />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ContactPage