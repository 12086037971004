import type { RadioGroupProps } from "@nextui-org/react";
import React from "react";
import { RadioGroup } from "@nextui-org/react";
import { cn } from "@nextui-org/react";
import RatingRadioItem from "./rating-radio-item";
import useFilterStore from "./useFilterStore";

export type RatingRadioGroupProps = RadioGroupProps & {
  hideStarsText?: boolean;
  onChange?: (value: number) => void; 
};

const RatingRadioGroup = React.forwardRef<HTMLDivElement, RatingRadioGroupProps>(
  ({ className, label, hideStarsText, onChange, ...props }, ref) => {
    const { rating } = useFilterStore();

    const starsText = React.useMemo(() => {
      return rating === 5 ? "5 stars" : `${rating} stars & up`;
    }, [rating]);

    const handleValueChange = (newValue: string) => {
      const numericValue = parseInt(newValue, 10);
      if (numericValue !== rating) {  // Only update if value is different
        useFilterStore.setState({ rating: numericValue });
        if (onChange) onChange(numericValue);
      }
    };

    return (
      <div className={cn("flex items-center gap-3", className)}>
        <RadioGroup
          ref={ref}
          value={String(rating)}
          {...props}
          defaultValue="1"
          orientation="horizontal"
          onValueChange={handleValueChange} // Use the new handler
        >
          <RatingRadioItem value="1" />
          <RatingRadioItem value="2" />
          <RatingRadioItem value="3" />
          <RatingRadioItem value="4" />
          <RatingRadioItem value="5" />
        </RadioGroup>
        {label && <span>{label}</span>}
        {!hideStarsText && <p className="text-medium text-default-400">{starsText}</p>}
      </div>
    );
  }
);

RatingRadioGroup.displayName = "RatingRadioGroup";

export default RatingRadioGroup;
