import type { Filter } from "./filters-types";
import useFilterStore from "./useFilterStore";
import React, {useState} from "react";
import {
  Accordion,
  AccordionItem,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  RadioGroup,
  ScrollShadow,
  Switch,
  Tab,
  Tabs,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { cn } from "@nextui-org/react";

import { FilterTypeEnum } from "./filters-types";

import ColorRadioItem from "./color-radio-item";
import PriceSlider from "./price-slider";
import RatingRadioGroup from "./rating-radio-group";
import TagGroupItem from "./tag-group-item";

export type FiltersWrapperProps = React.HTMLAttributes<HTMLDivElement> & {
  items: Filter[];
  title?: string;
  showTitle?: boolean;
  showActions?: boolean;
  className?: string;
  scrollShadowClassName?: string;
  onClose?: () => void;
};

const FiltersWrapper = React.forwardRef<HTMLDivElement, FiltersWrapperProps>(
  (
    {
      items,
      title = "Filters",
      showTitle = true,
      showActions = true,
      className,
      scrollShadowClassName,
      onClose
    },
    ref,
  ) => {

    const { priceRange, setPriceRange, rating, setRating, clearFilters, selectedAmenities, toggleAmenity } = useFilterStore();

    const renderFilter = React.useCallback((filter: Filter) => {
      switch (filter.type) {
        case FilterTypeEnum.Tabs:
          return (
            <Tabs fullWidth aria-label={filter.title}>
              {filter.options?.map((option) => <Tab key={option.value} title={option.title} />)}
            </Tabs>
          );
        case FilterTypeEnum.PriceRange:
          return <PriceSlider aria-label={filter.title} range={filter.range} onChange={(value) => setPriceRange(value as [number, number])} defaultValue={priceRange} />;
        case FilterTypeEnum.Rating:
          return <RatingRadioGroup onChange={(value) => setRating(parseInt(value, 10))}  />;
        case FilterTypeEnum.TagGroup:
          return (
            <CheckboxGroup
              aria-label="Select amenities"
              className="gap-1"
              orientation="horizontal"
            >
              {filter.options?.map((option) => {
                const isChecked = selectedAmenities.includes(option.value); 
                return (
                  <TagGroupItem key={option.value} icon={option.icon} value={option.value}>
                    <Checkbox
                      value={option.value}
                      isSelected={isChecked} 
                      onChange={() => toggleAmenity(option.value)} 
                    >
                      {option.title}
                    </Checkbox>
                  </TagGroupItem>
                );
              })}
            </CheckboxGroup>

          );
        case FilterTypeEnum.Toggle:
          return (
            <div className="-mx-4 flex flex-col">
              {filter.options?.map((option) => (
                <Switch
                  key={option.value}
                  classNames={{
                    base: cn(
                      "inline-flex flex-row-reverse w-full max-w-md bg-content1 hover:bg-content2 items-center",
                      "justify-between cursor-pointer rounded-lg gap-2 -mr-2 px-4 py-3",
                    ),
                    wrapper: "mr-0",
                  }}
                  value={option.value}
                >
                  <div className="flex flex-col gap-1">
                    <p className="text-medium">{option.title}</p>
                    <p className="text-tiny text-default-400">{option.description}</p>
                  </div>
                </Switch>
              ))}
            </div>
          );
        case FilterTypeEnum.CheckboxGroup:
          return (
            <Accordion
              className="px-0"
              defaultExpandedKeys={filter?.defaultOpen ? ["options"] : []}
            >
              <AccordionItem
                key="options"
                classNames={{
                  title: "text-medium font-medium leading-8 text-default-600",
                  trigger: "p-0",
                  content: "px-1",
                }}
                title={filter.title}
              >
                <CheckboxGroup aria-label={filter.title}>
                  {filter.options?.map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.title}
                    </Checkbox>
                  ))}
                </CheckboxGroup>
              </AccordionItem>
            </Accordion>
          );
        case FilterTypeEnum.Color:
          return (
            <RadioGroup
              aria-label={filter.title}
              classNames={{
                wrapper: "gap-2",
              }}
              orientation="horizontal"
            >
              {filter.options?.map((option) => (
                <ColorRadioItem
                  key={option.value}
                  color={option.color}
                  tooltip={option.title}
                  value={option.value}
                />
              ))}
            </RadioGroup>
          );
      }
    }, [setPriceRange, setRating]);

    const bookingOptions = items.filter((item) => item.title === "Booking Options");
    const otherFilters = items.filter((item) => item.title !== "Booking Options");

    return (
      <div
        ref={ref}
        className={cn("h-full max-h-fit w-full rounded-medium bg-content1 p-6", className)}
      >
        {showTitle && (
          <>
            <h2 className="text-large font-medium text-foreground">{title}</h2>
            <Divider className="my-3 bg-default-100" />
          </>
        )}
        <ScrollShadow
          className={cn(
            "-mx-6 h-full px-6 scrollbar-hide",
            {
              "max-h-[calc(100%_-_220px)]": showActions,
            },
            scrollShadowClassName,
          )}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col gap-6">
              {otherFilters.map((filter) => (
                <div key={filter.title} className="flex flex-col gap-3">
                  {filter.type !== FilterTypeEnum.CheckboxGroup ? (
                    <div>
                      <h3 className="text-medium font-medium leading-8 text-default-600">
                        {filter.title}
                      </h3>
                      <p className="text-small text-default-400">{filter.description}</p>
                    </div>
                  ) : null}
                  {renderFilter(filter)}
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-6">
              {bookingOptions.map((filter) => (
                <div key={filter.title} className="flex flex-col gap-3">
                  {filter.type !== FilterTypeEnum.CheckboxGroup ? (
                    <div>
                      <h3 className="text-medium font-medium leading-8 text-default-600">
                        {filter.title}
                      </h3>
                      <p className="text-small text-default-400">{filter.description}</p>
                    </div>
                  ) : null}
                  {renderFilter(filter)}
                </div>
              ))}
            </div>
          </div>
        </ScrollShadow>

        {showActions && (
          <>
            <Divider className="my-6 bg-default-100" />

            <div className="mt-auto flex flex-col gap-2">
              <Button
                color="primary"
                startContent={
                  <Icon
                    className="text-primary-foreground [&>g]:stroke-[3px]"
                    icon="solar:magnifer-linear"
                    width={16}
                  />
                }
                onPress={() => {if (onClose) onClose(); }}
              >
                Show 300+ stays
              </Button>
              <Button className="text-default-500" variant="flat"  onPress={() => {
                  clearFilters(); 
                  if (onClose) onClose(); 
                }}>
                Clear all filters
              </Button>
            </div>
          </>
        )}
      </div>
    );
  },
);

FiltersWrapper.displayName = "FiltersWrapper";

export default FiltersWrapper;
