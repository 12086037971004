import React, {useState} from 'react';
import { Modal, ModalContent, ModalBody} from "@nextui-org/modal";
import { Checkbox } from "@nextui-org/react";
import { Button } from '@nextui-org/button';
import { Input } from '@nextui-org/input';
import { Eye, EyeOff } from 'lucide-react';

const Register = ({ isOpen, onClose }: any) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
  return (
    <div className='w-full text-black'>
      <Modal isOpen={isOpen} onOpenChange={onClose}>
        <ModalContent>
            <ModalBody className="flex flex-col gap-4 px-8 py-6 text-black">
              <h3 className="text-xl font-bold px-3">Register</h3>
              <div className="flex flex-col gap-3 w-full">
                <Input 
                  className='pb-0 ' 
                  label="Email Address"
                  name="email"
                  type="email"
                  variant="bordered"/>
                
                <Input
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="bordered"
                  endContent={
                    <Button
                    radius="full"
                    variant="light"
                    className="text-xl"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    isIconOnly
                    >
                      {showPassword ? <Eye /> : <EyeOff />}
                    </Button>
                  }
                />

                <Input
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="bordered"
                  endContent={
                    <Button
                    radius="full"
                    variant="light"
                    className="text-xl"
                    onClick={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                    isIconOnly
                    >
                      {showConfirmPassword ? <Eye /> : <EyeOff />}
                    </Button>
                  }
                />
              </div>
                <Checkbox
                className="flex"
                isSelected={isSelected} onValueChange={setIsSelected}
                >
                I accept terms and conditions of real home
                </Checkbox>
                <Button
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Register
                </Button>
              
                <p className="text-center text-small">
                {"Already have an account?"}&nbsp;
                <span className="cursor-pointer text-primary hover:underline">
                  Log in
                </span>
              </p>
            </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default Register