import type { CheckboxProps } from "@nextui-org/react";
import React from "react";
import { Chip, VisuallyHidden, useCheckbox } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { cn } from "@nextui-org/react";
import useFilterStore from "./useFilterStore"; // Import Zustand store

export type TagGroupItemProps = Omit<CheckboxProps, "icon"> & {
  icon?: string;
  value: string; // Add value prop to identify amenity
};

const TagGroupItem = React.forwardRef<HTMLLabelElement, TagGroupItemProps>(
  ({ icon, value, size = "md", ...props }, ref) => {
    const { selectedAmenities, toggleAmenity } = useFilterStore(); // Access Zustand state and action

    // Determine if the current item is selected
    const isSelected = selectedAmenities.includes(value);

    // Checkbox hook
    const { children, isFocusVisible, getBaseProps, getLabelProps, getInputProps } = useCheckbox({
      ...props,
    });

    // Toggle state on click
    const handleClick = () => {
      toggleAmenity(value);
    };

    return (
      <label {...getBaseProps()} ref={ref} onClick={handleClick}>
        <VisuallyHidden>
          <input {...getInputProps()} />
        </VisuallyHidden>
        <Chip
          classNames={{
            base: cn({
              "outline-none ring-2 ring-focus ring-offset-2 ring-offset-background": isFocusVisible,
              "bg-primary": isSelected,
            }),
            content: cn("!text-small text-default-400", {
              "text-primary-foreground": isSelected,
              "pr-1": !!icon,
            }),
          }}
          radius="sm"
          size={size}
          startContent={
            icon ? (
              <Icon
                className={cn("text-default-400", {
                  "text-primary-foreground": isSelected,
                })}
                icon={icon}
                width={16}
              />
            ) : undefined
          }
          variant="flat"
        >
          {children}
        </Chip>
      </label>
    );
  }
);

TagGroupItem.displayName = "TagGroupItem";

export default TagGroupItem;
