import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import React from 'react'
import {Tabs, Tab} from "@nextui-org/tabs";

const LegalPage = () => {
  return (
    <div className='bg-white flex flex-col h-screen w-full justify-between'>
        <Navbar/>
            <div className='bg-white h-full w-full !max-w-3xl items-center px-4 py-8 pt-12 mx-auto sm:px-6 flex flex-col gap-8 text-md'>
                <h1 className="pb-2 w-full text-center text-black text-2xl font-bold">
                    Legal Documents
                </h1>
                <Tabs
                color="primary"
                size="lg"
                aria-label="Tabs colors"
                radius="lg"
                className="max-w-xl"
                fullWidth
                >
                <Tab key="privacy-policy" title="Privacy Policy">
                    Contrary to popular belief, Lorem Ipsum is not simply random text. It
                    has roots in a piece of classical Latin literature from 45 BC, making it
                    over 2000 years old. Richard McClintock, a Latin professor at
                    Hampden-Sydney College in Virginia, looked up one of the more obscure
                    Latin words, consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered the
                    undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
                    of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by
                    Cicero, written in 45 BC. This book is a treatise on the theory of
                    ethics, very popular during the Renaissance. The first line of Lorem
                    Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section
                    1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is
                    reproduced below for those interested. Sections 1.10.32 and 1.10.33 from
                    de Finibus Bonorum et Malorum by Cicero are also reproduced in their
                    exact original form, accompanied by English versions from the 1914
                    translation by H. Rackham.
                </Tab>
                <Tab key="terms-of-use" title="Terms of Use">
                    Contrary to popular belief, Lorem Ipsum is not simply random text. It
                    has roots in a piece of classical Latin literature from 45 BC, making it
                    over 2000 years old. Richard McClintock, a Latin professor at
                    Hampden-Sydney College in Virginia, looked up one of the more obscure
                    Latin words, consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered the
                    undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
                    of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by
                    Cicero, written in 45 BC. This book is a treatise on the theory of
                    ethics, very popular during the Renaissance. The first line of Lorem
                    Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section
                    1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is
                    reproduced below for those interested. Sections 1.10.32 and 1.10.33 from
                    de Finibus Bonorum et Malorum by Cicero are also reproduced in their
                    exact original form, accompanied by English versions from the 1914
                    translation by H. Rackham.
                </Tab>
                <Tab key="cookies-policy" title="Cookies Policy">
                    Contrary to popular belief, Lorem Ipsum is not simply random text. It
                    has roots in a piece of classical Latin literature from 45 BC, making it
                    over 2000 years old. Richard McClintock, a Latin professor at
                    Hampden-Sydney College in Virginia, looked up one of the more obscure
                    Latin words, consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered the
                    undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
                    of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by
                    Cicero, written in 45 BC. This book is a treatise on the theory of
                    ethics, very popular during the Renaissance. The first line of Lorem
                    Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section
                    1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is
                    reproduced below for those interested. Sections 1.10.32 and 1.10.33 from
                    de Finibus Bonorum et Malorum by Cicero are also reproduced in their
                    exact original form, accompanied by English versions from the 1914
                    translation by H. Rackham.
                </Tab>
                <Tab key="fair-housing" title="Fair Housing">
                    Contrary to popular belief, Lorem Ipsum is not simply random text. It
                    has roots in a piece of classical Latin literature from 45 BC, making it
                    over 2000 years old. Richard McClintock, a Latin professor at
                    Hampden-Sydney College in Virginia, looked up one of the more obscure
                    Latin words, consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered the
                    undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
                    of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by
                    Cicero, written in 45 BC. This book is a treatise on the theory of
                    ethics, very popular during the Renaissance. The first line of Lorem
                    Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section
                    1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is
                    reproduced below for those interested. Sections 1.10.32 and 1.10.33 from
                    de Finibus Bonorum et Malorum by Cicero are also reproduced in their
                    exact original form, accompanied by English versions from the 1914
                    translation by H. Rackham.
                </Tab>
                </Tabs>

            </div>
        <Footer/>
    </div>
  )
}

export default LegalPage