import React from "react";
import FiltersWrapper from "./filters-wrapper";
import getBookingItems from "./booking-items";

type Filters = {
  priceRange?: [number, number];
  rating?: number;
};

type BookingFiltersProps = {
  onClose?: () => void;
};


export default function BookingFilters({ onClose }: BookingFiltersProps) {
  return <FiltersWrapper items={getBookingItems()} onClose={onClose} />;
}
