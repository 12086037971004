import { create } from "zustand";

type FilterState = {
  priceRange: [number, number] ;
  rating: number ;
  selectedAmenities: string[];
  setPriceRange: (range: [number, number] | undefined) => void;
  setRating: (rating: number) => void;
  toggleAmenity: (amenity: string) => void;
  clearFilters: () => void;
};

const useFilterStore = create<FilterState>((set) => ({
  priceRange: [500, 2000],
  rating: 1,
  selectedAmenities: [],
  setPriceRange: (range) => set({ priceRange: range }),
  setRating: (rating) => set({ rating }),
  toggleAmenity: (amenity) => set((state) => {
    const isSelected = state.selectedAmenities.includes(amenity);
    return {
      selectedAmenities: isSelected
        ? state.selectedAmenities.filter((item) => item !== amenity)
        : [...state.selectedAmenities, amenity],
    };
  }),
    clearFilters: () =>
      set({
        priceRange: [500, 2000],
        rating: 1,
        selectedAmenities: [],
      }),
}));

export default useFilterStore;
